:root {
  --main-text-cl: #000000;
  --main-bg-cl: #ffffff;
  --secondary-bg-cl: #e5e5e5;
  --page-bg-cl: #e7eaf2;
  --authPage-secondary-bg-cl: #ffffff66;
  --loader-cl: #f3713f;
  --accent-cl: #4a56e2;
  --scroll-cl: #525861;
  --select-cl: #525861;
  --accent-faded-cl: #6e78e8;
  --accept-cl: #24cca7;
  --reject-cl: #ff6596;
  --faded-cl: #bdbdbd;
  --edit-cl: #e5ec0f;
  --remove-cl: #e22d1b;
  --faded-secondary-cl: #e0e0e0;
  --main-expenses-cl: #fed057;
  --products-cl: #ffd8d0;
  --car-cl: #fd9498;
  --selfcare-cl: #c5baff;
  --childcare-cl: #6e78e8;
  --household-cl: #4a56e2;
  --education-cl: #81e1ff;
  --leisure-cl: #24cca7;
  --entertainment-cl: #3cac08;
  --other-cl: #00ad84;
}