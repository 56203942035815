@font-face {
  font-family: Poppins;
  src: url("../assets/fonts/Poppins/Poppins-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Poppins;
  src: url("../assets/fonts/Poppins/Poppins-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Circe;
  src: url("../assets/fonts/Circe/Circe-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Circe;
  src: url("../assets/fonts/Circe/Circe-Bold.ttf");
  font-weight: 700;
}