@import "stylesheet/mixin.scss";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: Circe, system-ui, -apple-system, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1.5;
  background-color: var(--page-bg-cl);

  @include mq(tablet) {
    min-height: 100vh;
    background-image: url(../assets/images/loginPage/ellipse-top-tab-1x.png),
      url(../assets/images/loginPage/ellipse-bottom-tab-1x.png);

    background-image: -webkit-image-set(url(../assets/images/loginPage/ellipse-top-tab-1x.webp) 1x),
      -webkit-image-set(url(../assets/images/loginPage/ellipse-bottom-tab-1x.webp) 1x);
    background-image: image-set(url(../assets/images/loginPage/ellipse-top-tab-1x.webp) 1x),
      -webkit-image-set(url(../assets/images/loginPage/ellipse-bottom-tab-1x.webp) 1x);

    background-position: top right, bottom left;
    background-repeat: no-repeat;

    @include mq(retina) {
      background-image: url(../assets/images/loginPage/ellipse-top-tab-2x.png),
        url(../assets/images/loginPage/ellipse-bottom-tab-2x.png);
      background-image: -webkit-image-set(url(../assets/images/loginPage/ellipse-top-tab-2x.webp) 2x),
        -webkit-image-set(url(../assets/images/loginPage/ellipse-bottom-tab-2x.webp) 2x);
      background-image: image-set(url(../assets/images/loginPage/ellipse-top-tab-2x.webp) 2x),
        -webkit-image-set(url(../assets/images/loginPage/ellipse-bottom-tab-2x.webp) 2x);
    }
  }

  @include mq(desktop) {
    background-image: url(../assets/images/loginPage/ellipse-top-desk-1x.png),
      url(../assets/images/loginPage/ellipse-bottom-desk-1x.png);

    background-image: -webkit-image-set(url(../assets/images/loginPage/ellipse-top-desk-1x.webp) 1x),
      -webkit-image-set(url(../assets/images/loginPage/ellipse-bottom-desk-1x.webp) 1x);
    background-image: image-set(url(../assets/images/loginPage/ellipse-top-desk-1x.webp) 1x),
      -webkit-image-set(url(../assets/images/loginPage/ellipse-bottom-desk-1x.webp) 1x);

    @include mq(retina) {
      background-image: url(../assets/images/loginPage/ellipse-top-desk-2x.png),
        url(../assets/images/loginPage/ellipse-bottom-desk-2x.png);
      background-image: -webkit-image-set(url(../assets/images/loginPage/ellipse-top-desk-2x.webp) 2x),
        -webkit-image-set(url(../assets/images/loginPage/ellipse-bottom-desk-2x.webp) 2x);
      background-image: image-set(url(../assets/images/loginPage/ellipse-top-desk-2x.webp) 2x),
        -webkit-image-set(url(../assets/images/loginPage/ellipse-bottom-desk-2x.webp) 2x);
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

svg {
  fill: currentColor;
}

button {
  cursor: pointer;
}